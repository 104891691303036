//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  props: {
    show: Boolean,
  },
  data() {
    return {
      show_inner: false,
    };
  },
  watch: {
    show: function () {
      if (this.show) {
        this.show_inner = true;
      }
    },
    show_inner: function () {
      if (!this.show_inner) {
        this.$emit("close");
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "get_utente",
    }),
    iniziale() {
      return this.user.nome.substring(0, 1);
    },
  },
  methods: {
    logout() {
      this.$socket.close();
      this.$store.dispatch("logout");
    },
  },
};
