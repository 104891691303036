import { mapGetters } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapGetters({
      utente: "get_utente",
      qta_carrello: "get_qta_carrello",
      non_letti: "get_numero_msg_nl",
    }),
  },
  methods: {
    logout() {
      console.log("Logout");

      this.$store.dispatch("logout").then(() => {
        this.$socket.close();
      });
    },
  },
};
